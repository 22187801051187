<script setup lang="ts">
// icons
import { faTimesCircle } from '@fortawesome/pro-light-svg-icons';

/********************
 * PROPS & EMITS     *
 ********************/
export interface CiModalHeaderProps {
  closeable?: boolean;
  showCloseText?: boolean;
  title?: string | null;
}
const props = withDefaults(defineProps<CiModalHeaderProps>(), {
  closeable: true,
  showCloseText: true,
  title: '',
});

const emit = defineEmits<{
  'modal-close': [];
}>();

/********************
 * COMPOSITIONS      *
 ********************/
const { $gettext } = useGettext();
</script>

<template>
  <div
    v-if="props.title || props.closeable"
    class="flex items-center justify-between border-b border-gray-40 px-6 py-4 text-base font-bold md:text-2xl lg:text-3xl lg:[&>*]:leading-10"
  >
    <div
      v-if="props.title"
      class="mb-0"
    >
      {{ props.title }}
    </div>
    <div
      v-if="props.closeable"
      @click="emit('modal-close')"
    >
      <div class="flex h-full items-center justify-end text-sm uppercase hover:cursor-pointer hover:no-underline">
        <span
          v-if="showCloseText"
          class="hidden font-medium lg:block"
        >{{ $gettext('Close') }}</span>
        <CiAwesomeIcon
          :icon="faTimesCircle"
          class="full-dark ml-4"
          ratio="1.2"
        />
      </div>
    </div>
  </div>
</template>

<style></style>
